<template>
  <div>
      <label>Send Multicast Packet: </label>
      <form class="form-inline" id="send-multicast">
        <the-mask class="form-control mb-2 mr-sm-2" placeholder="Port" v-model="port" :mask="portPattern" :tokens="maskTokens"></the-mask>
        <the-mask class="form-control mb-2 mr-sm-2" placeholder="Payload" v-model="payload" :mask="payloadPattern" :tokens="maskTokens"></the-mask>
        <button class="btn btn-outline-secondary mb-2" @click.prevent="sendDownlink(port, payload)">Send</button>
      </form>
  </div>
</template>

<script>

import axios from 'axios';

function hexToBase64(hexstring) {
  return btoa(hexstring.match(/\w{2}/g).map(function(a) {
    return String.fromCharCode(parseInt(a, 16));
  }).join(""));
}

export default {
  props: {
    appId: { type: String, required: true },
  },
  data () {
    return {
        port: null,
        payload: '',
        maskTokens: {
          F: { pattern: /[0-9a-fA-F]/, transform: v => v.toLocaleUpperCase() },
          '#': { pattern: /\d/ },
        },
        payloadPattern: Array(64).fill('FF').join(' '),
        portPattern: '##',
    }
  },
  methods: {
    sendDownlink(port, payload) {
      axios.post(`/api/applications/${appId}/multicast`, {payload: hexToBase64(payload), port: port})
      .then(response => {
          this.$toasted.show('Multicast Downlink Scheduled', {
             position: "bottom-right",
             duration : 2000
          });
      })
      .catch(error => {
          this.$toasted.show(error.response.data.error, {
             type: "error",
             position: "bottom-right",
             duration : 2000
          });
      });
    }
  }
}
</script>
