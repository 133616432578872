<template>
<div class="file-upload">
  <div class="file btn btn btn-outline-primary">
    {{ btnLabel }}
    <input type="file" name="file" @change='onChangeInputFile' :accept='accept' :multiple='false' :disabled='uploading' ref='input' />
  </div>
</div>
</template>

<script>

import axios from 'axios';

export default {
  props: {
    url: { type: String, required: true },
    accept: { type: String, default: '.csv' },
    btnLabel: { type: String, default: 'Select a file' },
    maxSize: { type: Number, default: 15728640 }, // 15Mb
  },
  data() {
    return {
      uploading: false,
    }
  },
  methods: {
    onChangeInputFile(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const file = files[0]
      if (file.size > this.maxSize) {
        this.$emit('error', { code: 'max_size_exceded', message: `File max size exceded, upload a file smaller than ${this.maxSize}` })
        return
      }
      this.upload(file)
    },
    upload(file) {
      this.uploading = true;

      let formData = new FormData();
      formData.append('file', file);

      axios.post(this.url,
        formData,
        {headers: {'Content-Type': 'multipart/form-data'}}
      )
      .then(response => {
        this.$emit('success', response)
        this.uploading = false;
        this.cleanInput();
      })
      .catch(error => {
        this.$emit('error', error)
        this.uploading = false;
        this.cleanInput();
      });
    },
    cleanInput() {
      let input = this.$refs.input
      if (input) {
        input.value = ''
      }
    },
    onProgress(e) {
      this.progress = parseInt(e.loaded * 100 / e.total)
      this.$emit('progress', this.progress)
    },
  }
}
</script>

<style>
.file-upload {
  display: inline
}
.file-upload div {
  position: relative;
  overflow: hidden;
}
.file-upload input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
</style>
